<template>
  <section class="w-full">
    <div v-if="loading" class="rounded-md icono-carga flex justify-center items-center">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)"/>
    </div>
    <div class="accordion-item" v-else>
      <div id="faq-accordion-content-1" class="accordion-header flex justify-between">
        <button
          class="accordion-button text-base font-medium focus:outline-none dark:text-white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#faq-accordion-collapse-1"
          aria-expanded="true"
          aria-controls="faq-accordion-collapse-1"
        >
          Filtros de busqueda
        </button>
        <div class="flex justify-end">
          <Button icon="pi pi-search" @click="updateFilter()" class="p-button-sm p-button-info mr-2" v-tooltip.top="'Buscar'"/>
          <Button icon="pi pi-times" class="p-button-sm p-button-danger" @click="resetClear()" v-tooltip.top="'Limpiar'"/>
        </div>
      </div>
      <div
        id="faq-accordion-collapse-1"
        class="accordion-collapse collapse show mt-2"
        aria-labelledby="faq-accordion-content-1"
        data-bs-parent="#faq-accordion-1"
      >
        <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-2">
              <label for="dateStart" class="">Fecha inicio</label>
              <input
                v-model="filters.startDate"
                id="dateStart"
                type="date"
                class="form-control"
              >
              <MessageError :text="errors.startDate" />
            </div>
            <div class="p-field p-col-12 p-md-2">
              <label for="" class="">Fecha fin</label>
              <input
                v-model="filters.endDate"
                type="date"
                class="form-control"
                :min="filters.startDate"
              >
              <MessageError :text="errors.endDate" />
            </div>
            <div class="p-field p-col-12 p-md-1">
              <label for="documentNumber">N° Pago:</label>
              <InputText
                v-model="filters.numPago"
                id="documentNumber"
                type="text"
                placeholder="N°"
                class="h-10 border mt-1 rounded w-full"
              />
            </div>
            <div class="p-field p-col-12 p-md-2">
              <label for="documentTypes">Tipo de Documento:</label>
              <Dropdown
                id="documentTypes"
                v-model="filters.documentType"
                :options="documentTypes"
                optionLabel="name"
                optionValue="id"
                placeholder="Seleccione una opción"
                :filter="true"
                scrollHeight="auto"
                filterPlaceholder="Buscar por nombre"
                class="h-10 border mt-1 rounded w-full"
              />
            </div>
            <div class="p-field p-col-12 p-md-2">
              <label for="documentNumber">Prefijo / Num Documento:</label>
              <InputText
                v-model="filters.documentNumber"
                id="documentNumber"
                type="text"
                placeholder="Prefijo + num doc"
                class="h-10 border mt-1 rounded w-full"
              />
            </div>
            <div class="p-field p-col-12 p-md-2">
              <label for="fase">Fase:</label>
              <Dropdown
                id="fase"
                v-model="filters.stageCurrent"
                :options="stagesName"
                optionLabel="name"
                optionValue="id"
                placeholder="Seleccione una opción"
                :filter="true"
                scrollHeight="auto"
                filterPlaceholder="Buscar por nombre"
                class="h-10 border mt-1 rounded w-full"
              />
              <MessageError :text="errors.stageCurrent" />
            </div>
            <div class="p-field p-col-12 p-md-1">
              <label for="documentTypes">Estado:</label>
              <Dropdown
                id="documentTypes"
                v-model="filters.status"
                :options="statusName"
                optionLabel="name"
                optionValue="id"
                placeholder="Seleccione una opción"
                :filter="true"
                scrollHeight="auto"
                filterPlaceholder="Buscar por nombre"
                class="h-10 border mt-1 rounded w-full"
              />
              <MessageError :text="errors.status" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="flex flex-col sm:flex-row justify-between items-center border-t border-gray-300 dark:border-dark-5" ></div>
</template>

<script>

import { onMounted, ref } from 'vue'
export default {
  name: 'FiltersInvoices',
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  setup (props, context) {
    const loading = ref()
    const banks = ref()
    const errors = ref({
      fecha_final: '',
      fecha_inicial: '',
      estado: '',
      stage: ''
    })
    const obligations = ref()
    const creditLines = ref()
    const filters = ref(props.modelValue)
    const documentTypes = ref([
      { id: 1, name: 'Nota Crédito' },
      { id: 2, name: 'Factura' },
      { id: 3, name: 'Cuenta por pagar' }
    ])

    const stagesName = ref([
      { id: 'stageReceptionStatus', name: 'Recepción' },
      { id: 'stageRequestStatus', name: 'Solicitante' },
      { id: 'stageAreaStatus', name: 'Dir. Area' },
      { id: 'stageRegisterStatus', name: 'Contabilidad' },
      { id: 'stageAccountStatus', name: 'Dir. Contabilidad' },
      { id: 'stageManagerStatus', name: 'Autorización' }
    ])

    const statusName = ref([
      { id: 'Aceptado', name: 'Aceptado' },
      { id: 'Pendiente', name: 'Pendiente' },
      { id: 'Devuelto', name: 'Devuelto' }
    ])

    const updateFilter = () => {
      errors.value = {
        fecha_final: '',
        fecha_inicial: '',
        estado: '',
        stage: ''
      }
      if (filters.value.startDate !== '' && filters.value.endDate === '') errors.value.endDate = 'Debe seleccionar una fecha de fin'
      if (filters.value.startDate === '' && filters.value.endDate !== '') errors.value.startDate = 'Debe seleccionar una fecha de inicio'
      if (filters.value.stageCurrent === '' && filters.value.status !== '') errors.value.stageCurrent = 'Debe seleccionar una fase'
      if (filters.value.stageCurrent !== '' && filters.value.status === '') errors.value.status = 'Debe seleccionar un estado'
      for (const argumentsKey in errors.value) {
        if (errors.value[argumentsKey] !== '') {
          return
        }
      }

      context.emit('update:modelValue', filters.value)
      context.emit('search', { filters: filters.value })
    }

    const resetClear = () => {
      for (const key in filters.value) {
        filters.value[key] = ''
      }
      context.emit('update:modelValue', filters.value)
      context.emit('search', { filters: filters.value })
    }

    onMounted(() => {
    })

    return {
      errors,
      updateFilter,
      resetClear,
      stagesName,
      documentTypes,
      statusName,
      filters,
      creditLines,
      obligations,
      status,
      loading,
      banks
    }
  }
}
</script>

<style scoped>

</style>
